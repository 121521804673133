@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", Arial, Helvetica, sans-serif;

  --navbar-height: 100px;
  --margin-to-navbar: 50px;
  --margin-to-navbar-mobile: 10px;
  --color-primary: green;
  --color-secondary: #d2ffaa;
  --color-text: white;
  --color-button: #4caf50;
  --color-button-hover: #45a049;
  --color-button-delete: #f44336;
  --color-button-delete-hover: #d32f2f;
  --color-background: rgb(245, 245, 240);
  --color-popup-background: rgb(250, 250, 250);
}

body {
  background-color: var(--color-background);
}

.loading {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
}

.content {
  padding-top: var(--navbar-height);
}
