.events-container {
  margin: var(--margin-to-navbar) auto 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 20px;
  width: 1140px;
  border-radius: 40px;
  border: 4px solid var(--color-button);
}

.events-container h2 {
  text-align: center;
  margin: 15px 0;
}

.events-controls {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 15px 0 15px 0;
}

.events-controls button {
  margin-right: 5px;
  margin-left: 0px;
}

.events-controls button:last-child {
  margin-right: 0;
}

.events-controls .input-search {
  flex: 1 0 auto;
  margin-left: 30px;
  padding: 10px 15px;
}

.events-controls .sorting-select {
  margin-left: 30px;
  margin-right: 5px;
  padding: 10px 15px;
  width: 255px;
}

.events-table {
  width: 100%;
  margin-top: 0px;
  text-align: left;
  line-height: 1.5;
  border-collapse: collapse;

  th {
    background-color: var(--color-button);
    color: var(--color-text);
  }

  tr {
    cursor: pointer;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  td {
    word-break: break-word;
    border-bottom: 1px solid #ddd;
  }

  th,
  td {
    padding: 15px;
    text-align: left;
  }

  td:nth-child(1) {
    width: 50px;
  }

  td:nth-child(2) {
    width: 250px;
  }

  td:nth-child(3) {
    width: 150px;
  }

  td:nth-child(5) {
    width: 120px;
  }

  td:nth-child(6) {
    width: 120px;
  }
}

@media (max-width: 1200px) {
  .events-container {
    width: 95%;
    margin-top: var(--margin-to-navbar-mobile);
  }

  .events-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .events-controls button,
  .events-controls .input-search,
  .events-controls .sorting-select {
    margin: 5px 0px 5px 5px;
    width: 300px;
  }

  .events-table {
    td {
      display: block;
      padding: 5px;
      text-align: center;
      border-bottom: none;
    }

    tr {
      display: block;
      margin-bottom: 30px;
      padding: 20px;
      border-radius: 20px;
      border: 1px solid #ededed;
    }

    thead tr:first-child {
      display: none;
    }

    td:first-child {
      font-weight: bold;
    }

    td:before {
      content: attr(data-header);
      font-weight: bold;
    }

    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(5),
    td:nth-child(6) {
      width: auto;
    }
  }
}
