.popup-ratings {
  overflow-y: auto;
  margin: 5px 0;
  max-height: 192px;
  scrollbar-width: thin; 
  scrollbar-color: lightgrey transparent; 
  scrollbar-gutter: auto;
  scroll-behavior: auto;
}

.popup-rating {
  padding: 5px;
  border-bottom: 1px solid var(--color-primary);
}
  
.popup-rating:last-child {
  border: none;
}
  
.popup-rating-header {
  display: flex;
  align-items: center;
  line-height: 1rem;
  gap: 10px;
  color: var(--color-primary);
}

.popup-rating-header-username {
  font-weight: normal;
}

.popup-rating-comment {  
  color: black;
}