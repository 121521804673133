.place-ranking {
  list-style: none;
  counter-reset: item;
}
.place-ranking li {
  counter-increment: item;
  margin-bottom: 5px;
  font-size: 1.3rem;
}
.place-ranking li:before {
  margin-right: 10px;
  content: counter(item) ".";
  color: var(--color-button);
  width: 1.2em;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .place-ranking li,
  .place-ranking li:before {
    font-size: 1rem;
    line-height: 1.2;
  }
}
