.popup-upcoming-event {
  margin: 4px 4px 4px 0px;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  padding: 10px 20px;  
  cursor: pointer;
  transition: background-color 0.3s ease;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-upcoming-event-description {
  color: var(--color-primary);
}

.popup-upcoming-event:hover {  
  background-color: #e6f2e6;  
}