.navbar {
  width: 100%;
  height: var(--navbar-height);
  background-color: var(--color-primary);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
}

.navbar-container {
  margin: 0 30px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.navbar-logo img {
  height: var(--navbar-height);
}

.navbar-controls {
  display: flex;
  gap: 20px;
}

.navbar-controls button {
  letter-spacing: 1px;
  border-radius: 100px;
}

.hamburger {
  display: none;
}

@media (max-width: 1200px) {
  .navbar-container {
    justify-content: flex-end;
  }

  .navbar-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .navbar-controls {
    width: 100%;
    height: calc(100vh - var(--navbar-height));
    top: var(--navbar-height);
    left: -100%;
    position: fixed;
    flex-direction: column;
    background-color: var(--color-primary);
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: all 0.3s;
  }

  .navbar-controls.active {
    left: 0;
  }

  .navbar-controls button {
    border: 0;
    font-size: 2.5rem;
  }

  .hamburger {
    display: block;
  }
}
