.admin-container {
  margin-top: var(--margin-to-navbar);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.admin-subsection {
  padding: 20px;
  border-radius: 40px;
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  margin-bottom: 10px;
  border: 4px solid var(--color-button);
  justify-content: center;
  align-items: center;
  width: 1140px;
}

.admin-subsection:first-child {
  margin-top: 0;
}

.admin-table {
  width: 100%;
  padding: 0;
  margin-top: 30px;
  text-align: left;
  line-height: 1.5;
  padding-left: 0;

  th {
    background-color: var(--color-button);
    color: var(--color-text);
  }

  td {
    word-break: break-word;
    border-bottom: 1px solid #ddd;
  }

  th,
  td {
    padding: 15px;
    text-align: left;
  }

  td:nth-child(1) {
    min-width: 50px;
  }

  td:nth-child(5) {
    min-width: 200px;
  }
}

@media (max-width: 1200px) {
  .admin-container {
    margin: var(--margin-to-navbar-mobile) 10px 10px 10px;
  }

  .admin-subsection {
    width: 95%;
    margin: 5px auto;
  }

  .admin-table {
    td {
      display: block;
      padding: 5px;
      text-align: center;
      border-bottom: none;
    }

    tr {
      display: block;
      margin-bottom: 30px;
      padding: 20px;
      border-radius: 20px;
      border: 1px solid #ededed;
    }

    thead tr:first-child {
      display: none;
    }

    td:first-child {
      font-weight: bold;
    }

    td:before {
      content: attr(data-header);
      font-weight: bold;
    }
  }
}

.admin-table .table-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.admin-container .btn {
  font-size: 0.8rem;
  width: 90px;
}

.input-addplace {
  margin: 15px auto;
  display: block;
  text-align: center;
}
