.auth-container {
  border-radius: 40px;
  margin: var(--margin-to-navbar) auto;
  position: relative;
  padding: 30px 50px;
  max-width: 600px;
  border: 4px solid var(--color-primary);
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.auth-container .toggle-link {
  text-align: center;
  white-space: nowrap;
}

.auth-container .toggle-link a {
  text-decoration: none;
  font-size: 15px;
  color: var(--color-primary);
}

.auth-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.auth-container label {
  margin-bottom: 5px;
  width: 100%;
  max-width: 300px;
  text-align: left;
}

.auth-container input {
  width: 100%;
  max-width: 300px;
  height: 40px;
  margin-bottom: 15px;
}

#error-message {
  font-size: 15px;
  color: red;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 630px) {
  .auth-container {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: var(--margin-to-navbar-mobile);
  }
}
