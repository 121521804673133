.user-container {
  display: flex;
  width: 100%;
}

.user-container > * {
  height: calc(100vh - var(--navbar-height));
}
.map-container {
  width: 60%;
}
.user-container #map {
  width: 100%;
  height: 100%;
}

.controls {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: var(--color-background);
}

.close-controls-button-container {
  display: none;
}

.buttons-container {
  text-align: center;
}
.buttons-container button {
  margin: 10px 5px 0;
  width: 200px;
}

.control-panel {
  flex-grow: 1;
  overflow: hidden;
  text-align: center;
  border: solid var(--color-button) 4px;
  border-radius: 20px;
  margin: 20px 0;
}

.control-panel textarea {
  margin: 5px;
  border-radius: 10px;
  padding: 5px 10px;
  border: 2px solid var(--color-button);
  resize: none;
  width: 250px;
}

.control-panel input {
  margin: 3px auto;
}

.control-panel select {
  margin: 3px auto;
  border-radius: 10px;
  padding: 5px 10px;
  border: 2px solid var(--color-button);
  width: 300px;
  display: block;
  font-size: 1rem;
}
.control-panel label {
  margin: 10px 0px 2px 0px;
}

.panel-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.place-name {
  color: var(--color-button);
  font-size: 30px;
}

@media (max-width: 1200px) {
  .user-container {
    flex-direction: column;
  }
  .map-container {
    width: 100%;
  }
  .controls {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    transition: all 0.5s;
    padding: 10px;
    flex-direction: column-reverse;
  }
  .controls.active {
    height: 440px;
  }

  .control-panel {
    overflow: hidden;
    margin-top: 0;
    transition: opacity 0.5s;
  }

  .controls:not(.active) .control-panel {
    opacity: 0;
  }

  .close-controls-button-container {
    display: flex;
    justify-content: flex-end;
  }
  .close-controls-button {
    font-size: 2rem;
    cursor: pointer;
    color: red;
    padding: 5px 10px;
    transition: opacity 0.2s;
    transition-delay: 0.1s;
  }

  .controls:not(.active) .close-controls-button {
    opacity: 0;
    transition-delay: 0s;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .buttons-container button {
    height: 60px;
    margin: 0;
    flex: 1;
    font-size: 1rem;
  }

  .control-panel h1 {
    font-size: 1.2rem;
  }

  .control-panel input,
  .control-panel select {
    width: 200px;
  }

  .control-panel input,
  .control-panel select,
  .control-panel li {
    font-size: 0.9rem;
  }

  .control-panel li:before {
    font-size: 1.1rem;
  }
}
