.hamburger {
    width: 40px;
    cursor: pointer;
}

.hamburger-line {
  background-color: var(--color-secondary);
  width: 100%;
  height: 3px;
  transition: all 0.3s;
}
.hamburger-line.line-2 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.hamburger.active .line-1 {
  transform: translateY(8px) rotate(45deg);
}
.hamburger.active .line-2 {
  opacity: 0;
}
.hamburger.active .line-3 {
  transform: translateY(-8px) rotate(-45deg);
}