.btn {
  font-size: 1.2rem;
  border: none;
  border-radius: 15px;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  color: var(--color-text);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-primary {
  background-color: var(--color-button);
}

.btn-primary:hover {
  background-color: var(--color-button-hover);
}

.btn-delete {
  background-color: var(--color-button-delete);
}

.btn-delete:hover {
  background-color: var(--color-button-delete-hover);
}

.btn-outline {
  background-color: transparent;
  color: var(--color-secondary);
  border: 3px solid var(--color-secondary);
  font-weight: bold;
}

.btn-outline:hover {
  color: var(--color-text);
  border-color: var(--color-text);
}
