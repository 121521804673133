.popup-content {
  font-size: 14px;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-popup-background);
}

.popup-header {
  display: flex;
  align-items: center;
  gap: 40px;
}

.popup-header > * {
  line-height: 1.3rem;
}

.popup-avg-rating {
  color: var(--color-primary);
  white-space: nowrap;
}

.popup-info {
  margin-bottom: 10px;
}

.popup-info p {
  margin: 8px 0;
}

.popup-buttons {
  display: flex;
  flex-wrap: wrap;
}

.popup-buttons button {
  font-size: 0.9rem;
  margin: 4px 4px 4px 0px;
  flex: 1 auto;
}

.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  cursor: pointer;
}

.popup-close:hover {
  color: red;
}

@media (max-width: 768px) {
  .popup-content {
    width: 250px;
  }
}
