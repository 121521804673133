.event-details-wrapper {
  margin: var(--margin-to-navbar) 0 20px 0;
  padding: 0 20px;
}

.event-details-container {
  margin: 0 auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid var(--color-button) 4px;
  border-radius: 20px;
  padding: 20px 40px;
}

.event-details-container h2 {
  text-align: center;
}

.event-name {
  text-align: center;
}

.event-details-container .upcoming-title {
  color: #7cb9e8;
}
.event-details-container .ongoing-title {
  color: var(--color-button);
}
.event-details-container .finished-title {
  color: var(--color-button-delete);
}

h1.event-name {
  line-height: 36px;
  margin-bottom: 15px;
}

.event-details-container .input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  margin-bottom: 15px;
}

.event-details-container label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.event-details-container input {
  width: 100%;
}

.event-details-buttons {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.event-details-buttons button {
  flex: 1;
}

.participants-container {
  margin-top: 20px;
  text-align: center;
}

.participants-table {
  border: solid var(--color-button) 2px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 10px;
}

.user-row {
  padding: 5px;
  border-bottom: solid var(--color-button) 1px;
}

.user-row:last-child {
  border-bottom: none;
}

@media (max-width: 450px) {
  .settings-container {
    margin-top: var(--margin-to-navbar-mobile);
  }

  .event-details-container h1 {
    font-size: 1.5rem;
  }

  .event-details-container label {
    font-size: 0.8rem;
  }
}
