.rating-stars-container {
  font-size: 20px;
  margin: 10px;
}

.star {
  cursor: pointer;
  font-size: 40px;
}

.star-filled {
  color: var(--color-button);
}

.comment-box {
  height: 6rem;
  background-color: var(--color-background);
}

@media (max-width: 1200px) {
  .rating-stars-container {
    font-size: 15px;
  }

  .star {
    font-size: 30px;
  }

  .comment-box {
    height: 3rem;
  }
}
