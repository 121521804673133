.success-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  border-radius: 20px;
  padding: 40px;
  z-index: 1000;
  color: white;
  font-size: 25px;
  width: 70%;
  max-width: 800px;
}

.success-popup button {
  margin-top: 20px;
}
