#error-message {
  color: red;
}

#success-message {
  color: var(--color-primary);
}

.settings-container {
  margin-top: var(--margin-to-navbar);
  display: flex;
  width: 100%;
}

.settings-popup-content {
  font-size: 14px;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--color-text);
}

.settings-popup-title {
  margin-bottom: 10px;
}

.settings-popup-info {
  margin-bottom: 10px;
}

.settings-popup-info p {
  margin: 8px 0;
}

.settings-container > * {
  padding: 0px 20px;
  height: 570px;
}

.settings-controls {
  width: 700px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.settings-buttons-container {
  text-align: center;
}

.settings-buttons-container button {
  width: 200px;
  white-space: nowrap;
  margin: 0 5px 10px;
}

.settings-selected-panel-container {
  text-align: center;
  border: solid var(--color-button) 4px;
  border-radius: 20px;
  position: relative;
  padding: 0 20px;
  height: 100%;
}

.settings-control-panel {
  width: calc(100% - 2 * 20px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 20px));
}

.settings-control-panel label {
  margin: 10px 0px 2px 0px;
}

.settings-control-panel button {
  margin-top: 10px;
}

@media (max-width: 450px) {
  .settings-container {
    margin-top: var(--margin-to-navbar-mobile);
  }
  .settings-control-panel h1 {
    font-size: 1.5rem;
  }
  .settings-control-panel label {
    font-size: 0.8rem;
  }
}
