.home-container {
  margin: auto;
  width: 100%;
  height: calc(100vh - var(--navbar-height));
}

.map-container {
  width: 100%;
  height: calc(100vh - var(--navbar-height));
}
.map-container.full {
  width: 100% !important;
}

.home-container #map {
  width: 100%;
  height: 100%;
}
