.photos-panel {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
  margin-top: 15px;
}
.photos-panel-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.photos-header-add-button {
  position: absolute;
  right: 10px;
}
.photos-header-add-button button {
  width: 80px;
  padding: 10px 0;
}

.place-photos {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  overflow-y: auto;
}

.place-photo {
  position: relative;
}
.place-photo img {
  max-width: 300px;
  height: auto;
  border: 2px solid var(--color-button);
  border-radius: 10px;
  margin: 1px;
}
.place-photo .delete-photo {
  position: absolute;
  bottom: 15px;
  right: 10px;
  color: var(--color-button-delete);
  cursor: pointer;
  font-size: 1.5rem;
}
